<template>
  <main-content title="您的最佳选型">
    <div class="title r_name">{{ name }}</div>
    <div class="img">
      <img :src="img" />
    </div>
    <div class="title">设备视频</div>
    <video :src="video" controls></video>
    <div class="args">
      <div class="title">设备参数</div>
      <div class="item" v-for="(item, index) in content" :key="index">
        <div class="key">{{ index }}</div>
        <div class="value">{{ item }}</div>
      </div>
      <div class="tip">
        <p>以上选型结果仅供参考</p>
        <p>如需了解更多请联系我们：020-36398816</p>
      </div>
    </div>
  </main-content>
</template>

<script lang="ts">
import mainContent from "@/components/mainContent.vue";
import {
  defineComponent,
  onActivated,
  onDeactivated,
  reactive,
  toRefs,
} from "vue";
import { getResult } from "@/service/api";
import { imgURL } from "@/service/axios";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { mainContent },
  name: "result",
  setup() {
    const state = reactive({
      name: "",
      img: "",
      video: "",
      content: "",
    });

    const data = toRefs(state);
    const route = useRoute();
    onActivated(() => {
      const type = route.params.type as string;

      getResult(type).then((r) => {
        if (r.status) {
          const { name, content, file, video } = r.data;
          state.name = name;
          state.img = imgURL + file;
          state.video = video;
          state.content = JSON.parse(content);
        }
      });
    });

    onDeactivated(() => {
      state.name = "";
      state.img = "";
      state.video = "";
      state.content = "";
    });

    return {
      ...data,
    };
  },
});
</script>

<style lang="scss" scoped>
$baseColor: #00dd1b;
.img {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: center;

  img {
    width: 100%;
    max-width: 300px;
  }
}

video {
  width: 100%;
}

.title {
  font-size: 24px;
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 4vh;
}

.r_name {
  color: $baseColor;
  // font-weight: bold;
  font-size: 30px;
}

.args {
  .title {
    margin-top: 5vh;
    margin-bottom: 4vh;
  }

  .item {
    display: flex;
    line-height: 25px;
    & > div {
      flex: 1;
    }
  }
  .tip {
    margin-top: 10vh;
    color: #999;
    p {
      line-height: 20px;
    }
  }
}
</style>