
import mainContent from "@/components/mainContent.vue";
import {
  defineComponent,
  onActivated,
  onDeactivated,
  reactive,
  toRefs,
} from "vue";
import { getResult } from "@/service/api";
import { imgURL } from "@/service/axios";
import { useRoute } from "vue-router";

export default defineComponent({
  components: { mainContent },
  name: "result",
  setup() {
    const state = reactive({
      name: "",
      img: "",
      video: "",
      content: "",
    });

    const data = toRefs(state);
    const route = useRoute();
    onActivated(() => {
      const type = route.params.type as string;

      getResult(type).then((r) => {
        if (r.status) {
          const { name, content, file, video } = r.data;
          state.name = name;
          state.img = imgURL + file;
          state.video = video;
          state.content = JSON.parse(content);
        }
      });
    });

    onDeactivated(() => {
      state.name = "";
      state.img = "";
      state.video = "";
      state.content = "";
    });

    return {
      ...data,
    };
  },
});
